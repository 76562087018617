import React from 'react';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import {
  ListWithVisual,
  ListWithVisualItem,
  ListWithVisualIllustration,
} from '@uc-common/b-list-with-visual';
import { Icon } from '@uc-common/b-icon';
import { VisualBig } from '@uc-common/b-visual-big';

import { RecommendedContent } from '../../components/forBuilder/RecommendedContentFilestack';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';
import { Link } from '../../components/Link';
import { frontmatter as originalFrontmatter } from '../../builder-pages/migrate-from-filestack';

export const frontmatter = {
  noindex: true,
};

const MigrateFromFilestack = ({ location }) => {
  return (
    <CommonLayout
      meta={{
        ...originalFrontmatter,
        ...frontmatter,
      }}
      pathName={location.pathname}
    >
      <BLayout header={<Navbar mode={NavbarModesEnum.PPC} />}>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V1}
            title="Migrate from Filestack with ease"
            description="Thousands developers have forgotten about file handling issues with Uploadcare. We guarantee your team will breathe a sigh of relief, and we can even help you with migrating your file handling system."
            cta={
              <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                Get started
              </Button>
            }
          />
          <VisualBig
            src="https://ucarecdn.com/f13f0bea-2af4-415f-b386-1a0115d401c0/FirstScreenVisual12801.svg"
            sx={{
              paddingTop: PaddingEnum.VERTICAL_M,
              minHeight: ['150px', '330px', '420px', '490px'],
            }}
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <ListWithVisual
            title="You’ll feel the difference from day one"
            illustration={
              <ListWithVisualIllustration src="https://ucarecdn.com/7c865fd7-4308-4260-ba8e-d8faea036023/BenefitsVisual12804.svg" />
            }
            items={
              <>
                <ListWithVisualItem
                  icon={<Icon id="medal-boxed" />}
                  title="Smooth workflow"
                  description="No failed uploads, no hiccups in processing or delivery — this part of&nbsp;your app will just work."
                />
                <ListWithVisualItem
                  icon={<Icon id="checklist-boxed" />}
                  title="2-Year infrastructure roadmap"
                  description="Your files will find a home, and rest assured you won’t have to look for another one."
                />
                <ListWithVisualItem
                  icon={<Icon id="face-happy-boxed" />}
                  title="Fast and professional support"
                  description="Our engineers are always ready to help you via chat or email. Most cases are tackled in under 6 hours."
                />
              </>
            }
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head variant={HeadVariantsEnum.V2} title="Recommended content" />
          <RecommendedContent />
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_XL,
            paddingBottom: PaddingEnum.VERTICAL_L,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V2}
            title="Ready to transfer to Uploadcare?"
            description="At Uploadcare, it’s our customer success team’s specialty — helping you migrate your data and adopt our file handling platform with minimal disruption to your business. "
            cta={
              <>
                <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                  Try free
                </Button>
                <Button as={Link} to="/schedule-demo/" type={ButtonTypeEnum.DARK} ghost>
                  Contact the team
                </Button>
              </>
            }
          />
        </Section>
      </BLayout>
    </CommonLayout>
  );
};

export default MigrateFromFilestack;
